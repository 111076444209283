import { Button, Dialog, DialogBody, Spinner } from "@blueprintjs/core";
import React,{ useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { finishOperation } from "../kiosklog/ServerLogging";
import { strings } from "../actions/string";
import { UserAction } from "../actions/urserAction";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { firebase } from "../global/firebase";

let count =0
export const Thanks=()=>{
    const location = useLocation();
    const [businesses,setBusinesses]=useState(null as any)
    const [site,setsite]=useState(null as any)
    const [routes,setroutes]=useState([] as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)
    const [IsUpdate,setIsUpdate]=useState(false)
    const [userId,setUserId]=useState('');
    const [loading,setloading]=useState(false);
    const [isnew,setisnew]=useState(null as any);

    const [rewardHit,setrewardHit]=useState(false);

    const [getLoyaltyText,setgetLoyaltyText]=useState(false as any);
    const navigate= useNavigate();
    const [IsSubmited,setIsSubmited]=useState(false)
    const language  =location.state.language==='en' ? strings.en : strings.esp;
    useEffect(()=>{
        setBusinesses(location.state.business)            
        setsite(location.state.site)
        if(location.state.userId!==undefined && location.state.userId!==null){
          setUserId(location.state.userId)
        }
        setroutes(location.state.site.routes)
        if(location.state.site.showLoyaltyReward!==undefined && location.state.site.showLoyaltyReward===true){
        const businessId =location.state.business.businessId;
        const bguid = location.state.business.guid!==undefined && location.state.business.guid!==null ? location.state.business.guid : '';
        const sguid =location.state.site.guid!==undefined && location.state.site.guid!==null  ? location.state.site.guid :'';
        const kioskNameEs = location.state.site.kioskNameEs!==undefined && location.state.site.kioskNameEs!==null ? location.state.site.kioskNameEs :'';
        const kioskName = location.state.site.kioskName!==undefined && location.state.site.kioskName!==null ? location.state.site.kioskName : '';
        const language =location.state.language;
        const siteId =location.state.site.siteId;
        const mobile =location.state.mobile;
        if(!rewardHit){
          let guid =bguid
          if(sguid!==''){
            guid = sguid;
          }
        UserAction('getLoyaltyText',{businessId,language,siteId,mobile,guid,kioskNameEs,kioskName}).then(async(res)=> setgetLoyaltyText(res) )

        setrewardHit(true)
        }
        }
        if(count<=0){
        SubmitCheckins()
        }

       if(location.state.IsUpdated!==undefined && location.state.IsUpdated===true){        
        location.state.IsUpdating=false;
        location.state.IsUpdated=false;
        setTimeout(() => {         
            finishCheckIn()        
        },3000);
       } 
    })

   const maskNumber = (num: any) => {
        const x = num.toString().match(/(\d{3})(\d{3})(\d{4})/);
        return "(" + x[1] + ")" + " *** " + x[3];
      };

      const IsRecheckIn=async(data:any)=>{
        const d = new Date();
            d.setHours(0);    
        const checkIn =await getDocs(query(collection(firebase,'checkIns'),
        where('mobile','==',data.mobile),
        where('businessId','==',data.businessId),
        where('siteId','==',data.siteId),
        where('timestamp','>=',d),
        ))
        
        return checkIn.empty
    }
    
    const IsuserExistIn=async(data:any)=>{
        
        const checkIn =await getDocs(query(collection(firebase,'checkIns'),
        where('mobile','==',data.mobile),
        where('businessId','==',data.businessId),
        where('siteId','==',data.siteId),
        ))
        
        return checkIn.empty
    }


const SubmitCheckins=async()=>{  
  const checkinsRef = doc(collection(firebase, "checkIns"));
  console.log('submit checkIn') 
        count+=1
        setloading(true) 
        const site = location.state.site;
        const business = location.state.business;
        const waiver = location.state.language==='en'? location.state.site.routes.signature.waiverText : location.state.site.routes.signature.waiverTextEs ;
        const user = location.state.user;
        const firstName= location.state.firstName;
        const lastName=location.state.lastName;
        const mobile =location.state.mobile;
        const optin =location.state.optin;
        const zip=location.state.zip;
        const dateofbirth= location.state.dateofbirth;
     
        const vehicles=location.state.vehicles;
        const mugshot=location.state.mugshot!==null ? location.state.mugshot :'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=';
        const signature=location.state.signature;
        const customertype=location.state.customertype;
        const email=location.state.email;
        const Supercard=location.state.Supercard;
        const FinalCheckIn:any={
            businessId:business.businessId,
            businessPath:business.path,
            covidquestions:null,
            customertype:customertype,
            dateofbirth:dateofbirth,
            email:email,
            language:location.state.language,
            mobile:mobile,
            mugshot:mugshot,
            mugshotUrl:null,
            name:{
                displayName:firstName+' '+lastName,
                firstname:firstName,
                lastname:lastName,
            },
            optin:optin,
            receiptCode:null,
            signature:signature,
            signatureUrl:null,
            siteId:site.siteId,
            sitePath:site.path,
            supercard:Supercard,
            timestamp:new Date(),
            userId:null,
            vehicles:vehicles,
            waiver:waiver,
            zip:zip,
        }

        
        const FinalUser={
            covidquestions:null,
            dateofbirth:dateofbirth,
            email:email,
            hasAccount:false,
            hasPaidToday:true,
            language:location.state.language,
            loyaltyCycleText:false,
            mobile:mobile,
            mugshot:mugshot,
            mugshotUrl:null,
            optin:optin,
            name:{
                displayName:firstName+' '+lastName,
                firstname:firstName,
                lastname:lastName,
            },
            receiptCode:null,
            secretMobile:location.state.optOut===false ? maskNumber(mobile): null,
            signature:signature,
            signatureUrl:null,
            skipCheckIn:false,
            supercard:Supercard,
            userId:null,
            userRewardText:false,
            vehicles:vehicles,
            waiver:waiver,
            customertype:customertype,
            zip:zip,           
        }

        if(location.state.user===false && location.state.optOut===false){  
                    
        
         const userId =await  UserAction('addUser',FinalUser)  
          setUserId(userId)
          await  UserAction('updateUser',{userId})  
         FinalCheckIn.userId=userId;   
        }else if(location.state.optOut===false){
          
              const User = location.state.user;
              setUserId(User.userId)
              console.log(User,'yuyuu');

              FinalCheckIn.userId=User.userId;
              FinalCheckIn.kind=null;
              FinalCheckIn.zip=User.zip!==undefined ? User.zip:null;
              FinalCheckIn.supercard=User.supercard!==undefined ? User.supercard:null;
              FinalCheckIn.customertype=User.customertype!==undefined ? User.customertype:null;
              try {
                if(User.dateofbirth!==undefined &&  User.dateofbirth!==null && User.dateofbirth.seconds!==undefined){
                FinalCheckIn.dateofbirth=User.dateofbirth!==undefined ? new Date(User.dateofbirth.seconds* 1000):null;
                }else{
                  FinalCheckIn.dateofbirth=User.dateofbirth!==undefined ? User.dateofbirth:null;

                }

              } catch (error) {
                console.log(error);
                
              }
              FinalCheckIn.email=User.email!==undefined ? User.email:null;
              FinalCheckIn.name=User.name !==undefined ? User.name:null;

        }else if(location.state.optOut===true){
          FinalCheckIn.userId='opt-out';
        }
        if(FinalCheckIn.signature!==null){
            const filesign =await convertURIToImageData(FinalCheckIn.signature);                 
            const signUrl =  await UserAction('uploadImages',{file:filesign,businessId:location.state.business.businessId,checkinId:checkinsRef.id}) 
            FinalCheckIn.signatureUrl=signUrl;
         }
         if(FinalCheckIn.mugshot!==null){
            const filemugshot =await convertURIToImageData(FinalCheckIn.mugshot);                 
            const mugshotUrl =  await UserAction('uploadImages',{file:filemugshot,businessId:location.state.business.businessId,checkinId:checkinsRef.id}) 
            FinalCheckIn.mugshotUrl=mugshotUrl
         }
        
         
         if(FinalCheckIn.kind===undefined){
          setisnew(true)
        }else if(FinalCheckIn.kind!==undefined){
          setisnew(false)
        }
        FinalCheckIn.kioskSubmission = false;
         const response =  await UserAction('addCheckIn',{FinalCheckIn,checkinsRef});
         if(response){
            setloading(false)
            setTimeout(() => {
           
              if(location.state.IsUpdating===false){
              finishCheckIn()
              }
            },3000);
            
            return true;            
         } 
}

const finishCheckIn=async()=>{    
  setloading(true)

  //  const synth = window.speechSynthesis;
  //  let text = 'finish'
  //  try {
  //   if((location.state.site!==undefined && location.state.site!==null) && (location.state.site.finishPlay!==undefined && location.state.site.finishPlay===true)){
  //    if(location.state.site.finishPlayText!==undefined && location.state.site.finishPlayText!==null && location.state.site.finishPlayText!==''){
  //     text =location.state.site.finishPlayText
  //    }
  //     const utterThis = new SpeechSynthesisUtterance(text); 
  //     synth.speak(utterThis);

  //     utterThis.addEventListener("end",async (event) => {
     
  //       const finishopration= await finishOperation();
  //       setloading(!finishopration)    
  //       if(finishopration){       
  //       const path = '/'+location.state.business.path+'/'+location.state.site.path  
  //       navigate(path)
  //       count =0
  //       }

  //     });

  //   }else{

  //     const finishopration= await finishOperation();
  //     setloading(!finishopration)    
  //     if(finishopration){       
  //     const path = '/'+location.state.business.path+'/'+location.state.site.path  
  //     navigate(path)
  //     count =0
  //     }
      
  //   }
    
  //  } catch (error) {
    // console.log(error);
 
    const finishopration= await finishOperation();
    setloading(!finishopration)    
    if(finishopration){       
    const path = '/'+location.state.business.path+'/'+location.state.site.path  
    navigate(path)
    count =0
    }

    
  //  }

  


 
}


const UpdateUser=(x:any)=>{
    const path = '/'+businesses.path+'/'+site.path ;
    location.state.userId = userId;
    location.state.IsUpdating=true;
    setIsUpdate(true)
    switch (x) {
      case 'name':
        navigate(path+'/name',location)
        break;
      case 'zip':
        navigate(path+'/zip',location)
        break;
      case 'dateofbirth':
        navigate(path+'/dateofbirth',location)
        break;
      case 'vehicles':
        navigate(path+'/vehicles',location)
        break;
      case 'customertype':
        navigate(path+'/customertype',location)
        break;
      case 'email':
        navigate(path+'/email',location)
        break;
      case 'supercard':
        navigate(path+'/supercard',location)
        break;
      case 'optin':
        navigate(path+'/optin',location)
        break;
    
      default:
        break;
    }
    
}
const getUrlExtension = (url:any) => {
    try {  
    return url
      .split('/')[1]
      .split(";")[0]
      .trim();
              
    } catch (error) {
      return 'png'  
    }  
  }

const convertURIToImageData =async (url:any) => {    
    const imgExt = getUrlExtension(url);
    // const  rndstr=Math.random().toString(36).slice(2, 7).replace('.','x');
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], "sinature." + imgExt, {
      type: blob.type,
    });
    return file;
  }

    return(
        businesses!==null && site!==null ?
           <div  className='main-container thanksPage' style={{background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
       <div style={{display:'inline-grid',height:'100vh'}}>
            <h1 className="enternameTitle" style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>
              {site!==undefined && site!==null && (site.kioskUiVehicleConfig===undefined || site.kioskUiVehicleConfig===false ) ? language.complete :''} 
              {site!==undefined && site!==null && (site.kioskUiVehicleConfig!==undefined && site.kioskUiVehicleConfig===true ) ? language.welcomeThanksVehicleTap :''}           
              </h1>
        {site!==undefined && site!==null &&  window.location.href.includes('go-pull-it') && isnew!==null ? 
        <h1  className="thnaksPageMsg"  style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}
        >{  isnew ?  language.gopullitmsgwelcome : language.gopullitmsgsecond }</h1>:''}

        <div > <button   className="nextbuttoncss" style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!==null ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}}  disabled={loading} onClick={finishCheckIn}>{loading ?<ScaleLoader speedMultiplier={1} width={40} /> : language.finish}</button></div>
       <h2 className="thnaksPageMsg" style={{color:businesses.theme.colors.secondary!==undefined && businesses.theme.colors.secondary!==null ? businesses.theme.colors.secondary :'rgb(252, 173, 20)'}}>
     {getLoyaltyText!==false && !window.location.href.includes('go-pull-it')  ? <p>{getLoyaltyText}</p>  :''}
    {
    (routes.name!==undefined && routes.name.enabled!==undefined && routes.name.enabled) ||
    (routes.dateofbirth!==undefined && routes.dateofbirth.enabled!==undefined &&  routes.dateofbirth.enabled) ||
    (routes.customertype!==undefined && routes.customertype.enabled!==undefined &&  routes.customertype.enabled) ||
    (routes.email!==undefined && routes.email.enabled!==undefined &&  routes.email.enabled) ||
    (routes.supercard!==undefined && routes.supercard.enabled!==undefined &&  routes.supercard.enabled) ||
    (routes.zip!==undefined && routes.zip.enabled!==undefined &&  routes.zip.enabled) ||
    (routes.vehicles!==undefined && routes.vehicles.enabled!==undefined &&  routes.vehicles.enabled )||
    (routes.optin!==undefined && routes.optin.enabled!==undefined &&  routes.optin.enabled) ?
    language.updateProfile:''}
       </h2>
       {location.state.optOut===false ? 
       <div>
       {routes.name!==undefined && routes.name.enabled!==undefined && routes.name.enabled?<button disabled={loading} onClick={()=>UpdateUser('name')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Name</button>:''}
       {routes.dateofbirth!==undefined && routes.dateofbirth.enabled!==undefined &&  routes.dateofbirth.enabled?<button disabled={loading} onClick={()=>UpdateUser('dateofbirth')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Date of birth</button>:''}
       {routes.customertype!==undefined && routes.customertype.enabled!==undefined &&  routes.customertype.enabled?<button disabled={loading} onClick={()=>UpdateUser('customertype')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Customer Type</button>:''}
       {routes.email!==undefined && routes.email.enabled!==undefined &&  routes.email.enabled?<button disabled={loading} onClick={()=>UpdateUser('email')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Email</button>:''}
       {routes.supercard!==undefined && routes.supercard.enabled!==undefined &&  routes.supercard.enabled?<button disabled={loading} onClick={()=>UpdateUser('supercard')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Super Card</button>:''}
       {routes.zip!==undefined && routes.zip.enabled!==undefined &&  routes.zip.enabled?<button disabled={loading} onClick={()=>UpdateUser('zip')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Zip</button>:''}
       {routes.vehicles!==undefined && routes.vehicles.enabled!==undefined &&  routes.vehicles.enabled?<button disabled={loading} onClick={()=>UpdateUser('vehicles')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>Vehicles Pick</button>:''}
       {routes.optin!==undefined && routes.optin.enabled!==undefined &&  routes.optin.enabled?<button disabled={loading} onClick={()=>UpdateUser('optin')} className="nextbuttoncss" style={{margin:'5px',background: site.kioskUiButtonPrimaryColor!==undefined && site.kioskUiButtonPrimaryColor!==null && site.kioskUiButtonPrimaryColor!=='' ? site.kioskUiButtonPrimaryColor : 'rgb(43, 60, 139)'}}>optIn</button>:''}
       </div>:''}

        </div>

           </div>:<div>Loading...</div>
        )
}