import { useLocation, useNavigate } from "react-router-dom"

import React, { useEffect, useState } from 'react';
import { H1, Spinner } from "@blueprintjs/core";
import { ScaleLoader } from "react-spinners";
import { Keypads } from "../components/keypads";
import { errorInOperation } from "../kiosklog/ServerLogging";
import { strings } from "../actions/string";
import { UserAction } from "../actions/urserAction";
import { ExternalCss } from "../components/ExternalCss";

export const Email=()=>{
const location = useLocation();
const routes=location.state.site.routes;
const [businesses,setBusinesses]=useState(null as any)
const [site,setsite]=useState(null as any)
const [restartCheckIn,setrestartCheckIn]=useState(false)
const [IsUpdating,setIsUpdating]=useState(location.state.IsUpdating)
const [Nextdisable,setNextdisable]=useState(true)
const language  =location.state.language==='en' ? strings.en : strings.esp;
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const [email,setemail]=useState('')
const navigate= useNavigate();
useEffect(()=>{
    setBusinesses(location.state.business)            
    setsite(location.state.site)
    if(routes.email.enabled===false || ( location.state.user!==false && location.state.IsUpdating===false)){
        NextfinishButton()
    } 
})

const handleKeypadButton=(e:any)=>{
    setemail(email+e.target.value)
    if ((email+e.target.value).match(emailRegex)) {
        setNextdisable(false) 
    }else{
        setNextdisable(true) 
    }
        
}


const onHandleKeypadClick=()=>{
        const emailx = email.slice(0, -1);
        setemail(emailx)
        if ((emailx).match(emailRegex)) {       
        setNextdisable(false) 
        }else{
            setNextdisable(true) 
        }
      
}

const NextfinishButton=async()=>{
    const site = location.state.site;
    const business = location.state.business;
    const user = location.state.user;
    const firstName= location.state.firstName;
    const lastName=location.state.lastName;
    const mobile =location.state.mobile;
    const zip=location.state.zip;
    const dateofbirth= location.state.dateofbirth;
    const vehicles=location.state.vehicles;
    const mugshot=location.state.mugshot;
    const signature=location.state.signature;
    const customertype=location.state.customertype;
    const optOut=location.state.optOut;
    const lang=location.state.language;
    const path = '/'+business.path+'/'+site.path   
    if(location.state.IsUpdating){
        setNextdisable(true)
        await UserAction('updateUser',{userId:location.state.userId,email
      })
        location.state.userId = location.state.userId;
        location.state.IsUpdating=false;  
        location.state.IsUpdated=true; 
        setNextdisable(false)    
        navigate(path+'/thanks',location)
      }else{    
    navigate(path+'/supercard'  ,{state:{mobile,user,business,site,firstName,lastName,zip,dateofbirth,vehicles,mugshot,signature,customertype,email,language:lang,optOut,IsUpdating:false}})  
      }
}

const restartCheckInfun=async()=>{
    setrestartCheckIn(true)
    const  error = { 
           associated_data:JSON.stringify('restart checkIn from email screen'),
           class_name:'email',
           error_message:'restart checkIn',
           error_type:'component error',
           exception_message:JSON.stringify('restart checkIn'),
           file_name:'email.tsx',
           function_name:'email-Component',
           }
     const errorInOperationx = await errorInOperation(error);
     if(errorInOperationx){
     const path = '/'+businesses.path+'/'+site.path  
     navigate(path,{state:{business:location.state.business,site:location.state.site}})
       }
}
    return(
    businesses!==null && site!==null ? <div className='main-container' style={{background:site.kioskUiBackgroundColor!==undefined && site.kioskUiBackgroundColor!==null &&   site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background,height:'100vh'}}>
                    <div className="">
                    <ExternalCss/>
                        <h1 className="enternameTitle" style={{color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>{language.email}</h1>
                        <button  disabled={restartCheckIn} style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :language.restartCheckIn}</button>
                    </div>
                    <div className="inputgroup">
                    <input value={email}  readOnly={true} className={"EmailInput"} placeholder="" type="email"/>
                    </div>
                    <br/>   <br/>   <br/>
                    <Keypads
                    disable={false}
                    type="email"
                    handleKeypadButton={(e:any)=>handleKeypadButton(e)}
                    onHandleKeypadClick={()=>onHandleKeypadClick()}
                    business={businesses}
                    site={site}/>
                        <br/>
                    <div style={{display:'flex'}} className="centerDivName">
                    {routes.email.optional===true  && !IsUpdating ?  <button   style={{background:site.kioskUiButtonErrorColor!==undefined && site.kioskUiButtonErrorColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{language.NoThanks}</button>:''}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button  disabled={Nextdisable} style={{background:site.kioskUiButtonSuccessColor!==undefined && site.kioskUiButtonSuccessColor!==null && site.kioskUiButtonErrorColor!=='' ? site.kioskUiButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{IsUpdating ? language.update :language.NextFinish}</button>
                    </div>
     </div>: <p><Spinner  style={{marginTop:'40vh'}} intent='success' size={100} /></p>)
}